import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import navigationReducer from 'src/app/features/admin/store/navigationSlice';
import propertyReducer from 'src/app/features/property/store/propertySlice';
import authReducer from 'src/app/features/auth/store/authSlice';

export const store = configureStore({
  reducer: {
    navigation: navigationReducer,
    propertySearch: propertyReducer,
    auth: authReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
