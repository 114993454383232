import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'src/app/core/store/store';
import { SearchParams } from 'src/app/core/types/searchBox';

interface PropertySearchState {
  searchValues: SearchParams;
}

const initialState: PropertySearchState = {
  searchValues: {
    beds: '',
    q: '',
    range: '',
    type: '',
  },
};

export const propertySearchSlice = createSlice({
  name: 'propertySearch',
  initialState,
  reducers: {
    onPropertySearch: (state, action: PayloadAction<SearchParams>) => {
      state.searchValues = action.payload;
    },
  },
});

export const { onPropertySearch } = propertySearchSlice.actions;
export const selectPropertySearch = (state: RootState) =>
  state.propertySearch.searchValues;
export default propertySearchSlice.reducer;
