import { Select, Col, Input, DatePicker, Switch, Form } from 'antd'

export default function BasicDetails() {
  return (
    <>
      <Col xs={{ span: 24 }} lg={{ span: 24 }}>
        <Form.Item label='Name'>
          <Input type='text' placeholder='3 bedroom flat at Ikeja Lagos' />
        </Form.Item>
      </Col>
      <Col xs={{ span: 24 }} lg={{ span: 8 }}>
        <Form.Item label='Listing Type'>
          <Select
            placeholder='Select property type'
            options={[
              { label: 'Rent', value: 'rent' },
              { label: 'Sale', value: 'sale' },
              { label: 'Shortlet', value: 'shortlet' }
            ]}
          />
        </Form.Item>
      </Col>
      <Col xs={{ span: 24 }} lg={{ span: 8 }}>
        <Form.Item label='Property Type'>
          <Select
            placeholder='Select property type'
            options={[
              { label: 'Apartment', value: 'apartment' },
              { label: 'Duplex', value: 'duplex' },
              { label: 'Bungalow', value: 'bungalow' },
              { label: 'Flat', value: 'flat' },
              { label: 'Land', value: 'land' }
            ]}
          />
        </Form.Item>
      </Col>
      <Col xs={{ span: 24 }} lg={{ span: 8 }}>
        <Form.Item label='Tenure'>
          <Select
            placeholder='Select tenure'
            options={[
              { label: 'Month', value: 'month' },
              { label: 'Year', value: 'year' }
            ]}
          />
        </Form.Item>
      </Col>
      <Col xs={{ span: 24 }} lg={{ span: 8 }}>
        <Form.Item label='Bedrooms'>
          <Select
            placeholder='Select number of bedrooms'
            options={[
              { label: 'One', value: 1 },
              { label: 'Two', value: 2 },
              { label: 'Three', value: 3 },
              { label: 'Four', value: 4 },
              { label: 'Five', value: 5 }
            ]}
          />
        </Form.Item>
      </Col>
      <Col xs={{ span: 24 }} lg={{ span: 8 }}>
        <Form.Item label='Bathrooms'>
          <Select
            placeholder='Select number of bathrooms'
            options={[
              { label: 'One', value: 1 },
              { label: 'Two', value: 2 },
              { label: 'Three', value: 3 },
              { label: 'Four', value: 4 },
              { label: 'Five', value: 5 }
            ]}
          />
        </Form.Item>
      </Col>
      <Col xs={{ span: 24 }} lg={{ span: 8 }}>
        <Form.Item label='Date Avalailable'>
          <DatePicker style={{ width: '100%' }} />
        </Form.Item>
      </Col>
      <Col xs={{ span: 24 }} lg={{ span: 6 }}>
        <Form.Item label='Furnished'>
          <Switch />
        </Form.Item>
      </Col>
      <Col xs={{ span: 24 }} lg={{ span: 6 }}>
        <Form.Item label='Wifi'>
          <Switch />
        </Form.Item>
      </Col>
      <Col xs={{ span: 24 }} lg={{ span: 6 }}>
        <Form.Item label='Bills Included'>
          <Switch />
        </Form.Item>
      </Col>
      <Col xs={{ span: 24 }} lg={{ span: 6 }}>
        <Form.Item label='Pets'>
          <Switch />
        </Form.Item>
      </Col>
    </>
  )
}
