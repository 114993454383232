import { DeleteOutlined, EditOutlined, MoreOutlined } from '@ant-design/icons'
import { Avatar, Card, Col, Dropdown, Row, Typography } from 'antd'
import Table from 'antd/es/table'
import { Link } from 'react-router-dom'

export default function ViewProperties() {
  const { Title } = Typography
  const getName = (name: any) => (
    <>
      <Avatar.Group>
        <Avatar className='shape-avatar' shape='square' size={60}></Avatar>
        <div className='avatar-info'>
          <Title level={5}>{name}</Title>
        </div>
      </Avatar.Group>{' '}
    </>
  )
  const dataSource = [
    {
      key: '1',
      name: getName('3 bed at 123 Main St'),
      listingType: 'Rent',
      propertyType: 'Studio',
      bedrooms: 1,
      bathrooms: 1,
      price: '$500',
      status: 'Available',
      createdAt: '2021-09-01'
    },
    {
      key: '2',
      name: getName('2 bed ensuite at Lekki'),
      listingType: 'Sale',
      propertyType: 'Duplex',
      bedrooms: 3,
      bathrooms: 3,
      price: '$22,500',
      status: 'Available',
      createdAt: '2021-09-01'
    },
    {
      key: '3',
      name: getName('3 bed at 123 Main St'),
      listingType: 'Sale',
      propertyType: 'Terrace',
      bedrooms: 3,
      bathrooms: 1,
      price: '$500,000',
      status: 'Sold',
      createdAt: '2021-09-01'
    },
    {
      key: '3',
      name: getName('3 bed at 123 Main St'),
      listingType: 'Shortlet',
      propertyType: 'Flat',
      bedrooms: 2,
      bathrooms: 1,
      price: '$500',
      status: 'Rented',
      createdAt: '2021-09-01'
    }
  ]
  const menuItems = [
    {
      label: 'Edit',
      key: '0',
      icon: <EditOutlined />
    },
    {
      label: 'Delete',
      key: '1',
      icon: <DeleteOutlined />
    }
  ]
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text: string) => <Link to='#'>{text}</Link>
    },
    {
      title: 'LISTING TYPE',
      dataIndex: 'listingType',
      key: 'listingType'
    },
    {
      title: 'PROPERTY TYPE',
      dataIndex: 'propertyType',
      key: 'propertyType'
    },
    {
      title: 'BEDROOMS',
      dataIndex: 'bedrooms',
      key: 'bedrooms'
    },
    {
      title: 'BATHROOMS',
      dataIndex: 'bathrooms',
      key: 'bathrooms'
    },
    {
      title: 'PRICE',
      dataIndex: 'price',
      key: 'price'
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status'
    },
    {
      title: 'CREATED AT',
      dataIndex: 'createdAt',
      key: 'createdAt'
    },
    {
      title: '',
      dataIndex: 'actions',
      key: 'actions',
      render: (text: string) => {
        return (
          <Dropdown trigger={['click']} menu={{ items: menuItems }}>
            <MoreOutlined />
          </Dropdown>
        )
      }
    }
  ]
  return (
    <Row className='tabled'>
      <Col xs={{ span: 24 }} lg={{ span: 24 }}>
        <Card title='Properties' className='criclebox tablespace mb-24'>
          <div className='table-responsive'>
            <Table className='ant-border-space' columns={columns} dataSource={dataSource} pagination={false} />
          </div>
        </Card>
      </Col>
    </Row>
  )
}
