import { DeleteOutlined, EditOutlined, MoreOutlined } from '@ant-design/icons'
import { Row, Col, Card, Radio, Table, Avatar, Typography, Button, Tag, Dropdown } from 'antd'

export default function ViewUsers() {
  const { Title } = Typography
  const columns = [
    {
      title: 'NAME',
      dataIndex: 'name',
      key: 'name',
      width: '32%'
    },
    {
      title: 'ROLE',
      dataIndex: 'function',
      key: 'function'
    },

    {
      title: 'STATUS',
      key: 'status',
      dataIndex: 'status'
    },
    {
      title: 'CREATED AT',
      key: 'createdAt',
      dataIndex: 'createdAt'
    }
  ]

  const users = [
    {
      name: 'Michael John',
      email: 'michael@mail.com',
      type: 'Agent',
      company: 'Organization',
      isArchived: false
    },
    {
      name: 'Michael John',
      email: 'michael@mail.com',
      type: 'User',
      company: 'Organization',
      isArchived: false
    },
    {
      name: 'Michael John',
      email: 'michael@mail.com',
      avatarSrc: 'https://xsgames.co/randomusers/avatar.php?g=pixel',
      type: 'Agent',
      company: 'Organization',
      isArchived: true
    },
    {
      name: 'Michael John',
      email: 'michael@mail.com',
      avatarSrc: 'https://xsgames.co/randomusers/avatar.php?g=pixel',
      type: 'User',
      company: 'Organization',
      isArchived: false
    },
    {
      name: 'Michael John',
      email: 'michael@mail.com',
      avatarSrc: 'https://xsgames.co/randomusers/avatar.php?g=pixel',
      type: 'User',
      company: 'Organization',
      isArchived: false
    },
    {
      name: 'Michael John',
      email: 'michael@mail.com',
      avatarSrc: 'https://xsgames.co/randomusers/avatar.php?g=pixel',
      type: 'User',
      company: 'Organization',
      isArchived: false
    }
  ]

  const menuItems = [
    {
      label: 'Edit',
      key: '0',
      icon: <EditOutlined />
    },
    {
      label: 'Archive',
      key: '1',
      icon: <DeleteOutlined />
    }
  ]

  const data = users.map((user, key) => ({
    key: `${key}`,
    name: (
      <>
        <Avatar.Group>
          <Avatar
            className='shape-avatar'
            shape='square'
            size={40}
            src={`https://xsgames.co/randomusers/avatar.php?g=pixel&key=${key}`}
          ></Avatar>
          <div className='avatar-info'>
            <Title level={5}>{user.name}</Title>
            <p>{user.email}</p>
          </div>
        </Avatar.Group>{' '}
      </>
    ),
    function: (
      <>
        <div className='author-info'>
          <Title level={5}>{user.type}</Title>
          <p>{user.company}</p>
        </div>
      </>
    ),

    status: <Tag color={user.isArchived ? '' : 'green'}>{user.isArchived ? 'inactive' : 'active'}</Tag>,
    createdAt: (
      <>
        <div className='ant-employed'>
          <span>14/04/17</span>
          <Dropdown trigger={['click']} menu={{ items: menuItems }}>
            <Button type='link' shape='circle' className='ant-dropdown-link' icon={<MoreOutlined />} />
          </Dropdown>
        </div>
      </>
    )
  }))

  return (
    <Row className='tabled' gutter={[24, 0]}>
      <Col xs='24' xl={24}>
        <Card
          bordered={false}
          className='criclebox tablespace mb-24'
          title='Users Table'
          extra={
            <>
              <Radio.Group defaultValue='a'>
                <Radio.Button value='a'>Agent</Radio.Button>
                <Radio.Button value='b'>User</Radio.Button>
              </Radio.Group>
            </>
          }
        >
          <div className='table-responsive'>
            <Table columns={columns} dataSource={data} pagination={false} className='ant-border-space' />
          </div>
        </Card>
      </Col>
    </Row>
  )
}
