import { RouteObject } from 'react-router-dom';
import { Main } from '../core/components';
import { Home } from '../features/home';
import Property from '../features/property/components/Property';
import { PropertyDetails } from '../features/property-details';
import AboutUs from '../features/about-us/AboutUs';
import Signup from '../features/auth/signup/Signup';
import Login from '../features/auth/login/Login';
import AdminDashboard from '../features/admin/AdminDashboard';
import Dashboard from '../features/admin/dashboard/Dashboard';
import ViewProperties from '../features/admin/property/ViewProperties';
import ViewProperty from '../features/admin/property/Property';
import ListProperty from '../features/admin/property/ListProperty';
import ViewUsers from '../features/admin/user/ViewUsers';
import AuthRoutesGuard from './guards/authRoutesGuard';
import ProtectedRoutesGuard from './guards/protectedRoutesGuard';
import ResetPassword from '../features/auth/reset-password';
import NotFoundPage from '../features/NotFound';

const routesObject: RouteObject[] = [
  // { path: '/oauth', element: <Auth /> },
  {
    element: <AuthRoutesGuard />,
    children: [
      {
        path: 'register',
        element: <Signup />,
      },
      {
        path: 'login',
        element: <Login />,
      },
      {
        path: 'reset-password',
        element: <ResetPassword />,
      },
      {
        path: '*',
        element: <NotFoundPage />,
      },
    ],
  },
  {
    path: '/',
    element: <Main />,
    children: [
      { index: true, element: <Home /> },
      { path: 'property', element: <Property /> },
      { path: 'property/:id', element: <PropertyDetails /> },
      { path: 'about-us', element: <AboutUs /> },
      {
        path: '*',
        element: <NotFoundPage />,
      },
    ],
  },
  // Protected route guard
  {
    element: <ProtectedRoutesGuard />,
    children: [
      {
        path: '/dashboard',
        element: <AdminDashboard />,
        children: [
          { index: true, element: <Dashboard /> },
          { path: 'properties', element: <ViewProperties /> },
          { path: 'properties/:id', element: <ViewProperty /> },
          { path: 'properties/create', element: <ListProperty /> },
          { path: 'users', element: <ViewUsers /> },
          { path: 'users/:id', element: <ViewProperty /> },
          { path: 'users/create', element: <ListProperty /> },
          { path: 'saved-properties', element: <div>Saved Property</div> },
          {
            path: '*',
            element: <NotFoundPage path='/dashboard' />,
          },
        ],
      },
    ],
  },
];

export default routesObject;
