import { Button, Drawer, Space } from 'antd';

interface Props {
  open: boolean;
  onClose: VoidFunction;
}

export default function AdvancedSearchDrawer(props: Props) {
  const { open, onClose } = props;

  return (
    <Drawer
      title={'Property Search'}
      placement='right'
      closable={false}
      onClose={onClose}
      open={open}
      extra={
        <Space>
          <Button onClick={onClose}>Cancel</Button>
          <Button type='primary' onClick={onClose}>
            Search
          </Button>
        </Space>
      }
    >
      <p>Some contents...</p>
    </Drawer>
  );
}
