import { useEffect, useMemo } from 'react';
import { TOKEN_STORAGE_KEY } from '../lib/variables';
import { useAppDispatch, useAppSelector } from '../core/store/hooks';
import {
  clearAuthState,
  selectAuthError,
  selectAuthLoading,
  selectIsAuthenticated,
  selectUser,
} from '../features/auth/store/authSlice';
import { thunkGetAuthUser } from '../features/auth/store/thunks';
import showMessage from '../core/utils/show-message';

export default function useAuthUser() {
  const token = useMemo(() => localStorage.getItem(TOKEN_STORAGE_KEY), []);
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const authLoading = useAppSelector(selectAuthLoading);
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const error = useAppSelector(selectAuthError);

  useEffect(() => {
    if (error) {
      // This is a good place to show a toast or a modal
      showMessage('error', error.message);
    }
  }, [error]);

  useEffect(() => {
    if (isAuthenticated === true) {
      return;
    }

    if (!token) {
      // This does not redirect or clear the token, it only updates the state
      // so maybe rename it to something like `clearAuthState`
      dispatch(clearAuthState());
      return;
    }

    if (!user) {
      dispatch(thunkGetAuthUser())
        .unwrap()
        .catch((error) => {
          if (error.name === 'NotAuthenticated') {
            return;
          }
        });
    }
  }, [token, user, isAuthenticated, dispatch]);

  return { user, authLoading, isAuthenticated, token };
}
