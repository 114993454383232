import {
  CloudOutlined,
  TeamOutlined,
  ShopOutlined,
  DashboardOutlined,
} from '@ant-design/icons';
import { Menu, MenuProps } from 'antd';
import { Link } from 'react-router-dom';
import { AppLogo } from 'src/app/core/components';

export default function AdminSiderMenu({ mode }: { mode: 'vertical' | 'inline' }) {
  const items: MenuProps['items'] = [
    {
      key: 'dashboard',
      icon: <DashboardOutlined />,
      label: <Link to={'/dashboard'}>Dashboard</Link>,
    },
    {
      key: 'users',
      icon: <TeamOutlined />,
      label: 'Users',
      children: [
        {
          key: '/users',
          label: <Link to={'/dashboard/users'}>View</Link>,
        },
        {
          key: '/users/create',
          label: <Link to={'/dashboard/users/create'}>Create</Link>,
        },
      ],
    },
    {
      key: 'properties',
      icon: <ShopOutlined />,
      label: 'Properties',
      children: [
        {
          key: 'properties',
          label: <Link to={'/dashboard/properties'}>View</Link>,
        },
        {
          key: 'properties/create',
          label: (
            <Link to={'/dashboard/properties/create'}>
              <span className='label'>Create</span>
            </Link>
          ),
        },
      ],
    },
    {
      key: 'saved-properties',
      icon: <CloudOutlined />,
      label: <Link to={'/dashboard/saved-properties'}>My Saved Properties</Link>,
    },
  ];

  return (
    <>
      <AppLogo />
      <hr />
      <Menu
        theme='light'
        mode={mode}
        defaultSelectedKeys={['4']}
        items={items}
        className='admin-sidebar-menu'
      />
    </>
  );
}
