/**
 * Decode string
 */

export const decodeString = (str: string) =>
  decodeURIComponent(
    Array.prototype.map
      .call(atob(str), (c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
      .join('')
  );

/**
 * Decode string
 */
export const encodeString = (str: string) =>
  btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (_, p1) =>
      String.fromCharCode(parseInt(p1, 16))
    )
  );

// Function to filter out keys with undefined values
export const filterUndefinedValues = (obj: {}) => {
  return Object.fromEntries(
    Object.entries(obj).filter(([_, value]) => value !== undefined)
  );
};

export function replaceSpacesWithPlus(str: string) {
  return str.replace(/ /g, '+');
}
