import { Form, Input, Select, Button } from 'antd';
import { ControlOutlined, SearchOutlined } from '@ant-design/icons';
import './SearchBox.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { SearchParams } from '../../types/searchBox';
import { filterUndefinedValues } from '../../utils/helpers';
import useModalState from 'src/app/hooks/useModalState';
import AdvancedSearchDrawer from './components/AdvancedSearchDrawer';

interface Props {
  setQuery: (q: SearchParams) => void;
}

export function SearchBox(props: Props) {
  const { isOpen, closeModal, openModal } = useModalState();

  const { setQuery } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const isSearchPage = location.pathname === '/search';

  const handleSearchQuery = (values: SearchParams) => {
    if (isSearchPage) {
      setQuery({ ...values });
    } else {
      let filteredQueryObj = filterUndefinedValues(values);
      let queryParams = new URLSearchParams({ ...(filteredQueryObj as {}) });
      Object.values(filteredQueryObj).length > 0
        ? navigate(`/property?${queryParams.toString()}`, {
            state: { ...filteredQueryObj },
          })
        : navigate('/');
    }
  };

  return (
    <>
      <Form layout='vertical' className='search-form' onFinish={handleSearchQuery}>
        <div className='search-box'>
          <div className='search-box__item'>
            <Form.Item name={'location'} className='first-item' label={'Location'}>
              <Input placeholder='Where do you want to live?' />
            </Form.Item>
          </div>
          <div className='search-box__item responsive'>
            <Form.Item name={'type'} label={'Property Type'}>
              <Select
                placeholder='Select type'
                options={[
                  { label: 'Flat', value: 'flat' },
                  { label: 'Duplex', value: 'duplex' },
                ]}
              />
            </Form.Item>
          </div>
          <div className='search-box__item responsive'>
            <Form.Item name={'bed'} label={'Bed'}>
              <Select
                placeholder='Select beds'
                options={[
                  { label: 'One', value: 1 },
                  { label: 'Two', value: 2 },
                ]}
              />
            </Form.Item>
          </div>
          <div className='search-box__item responsive'>
            <Form.Item name={'price'} label={'Price'}>
              <Select
                placeholder='Select range'
                options={[
                  { label: '$1,000', value: 1000 },
                  { label: '$1,500', value: 1500 },
                ]}
              />
            </Form.Item>
          </div>
          {isSearchPage && (
            <div className='search-box__button'>
              <Form.Item label=' '>
                <Button icon={<ControlOutlined />} onClick={openModal}>
                  Filters
                </Button>
              </Form.Item>
            </div>
          )}
          {isSearchPage && (
            <div className='search-box__button'>
              <Form.Item label=' '>
                <Button type='primary' htmlType='submit' icon={<SearchOutlined />}>
                  Search
                </Button>
              </Form.Item>
            </div>
          )}
        </div>
        {!isSearchPage && (
          <div className='search-home-btn'>
            <Button type='primary' htmlType='submit' icon={<SearchOutlined />}>
              Search
            </Button>
          </div>
        )}
      </Form>

      <AdvancedSearchDrawer open={isOpen} onClose={closeModal} />
    </>
  );
}
